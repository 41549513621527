import React, { useEffect, useRef } from 'react';

import { Wrapper } from './table-container.styled';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import { GapSizeType } from '../../../../theme/type/gap-type';

export interface TableContainerPropsInterface {
    children: React.ReactElement;
    mobileBreakUnder?: BreakpointSizeType;
    onScrollCallback: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    onLoadCallback: (e: HTMLDivElement | null) => void;
    containerOverflow: boolean;
    marginBottom: GapSizeType;
}

export const TableContainer: React.FC<TableContainerPropsInterface> = ({
    children,
    mobileBreakUnder,
    onScrollCallback,
    onLoadCallback,
    containerOverflow,
    marginBottom,
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        onLoadCallback(containerRef.current);
    }, [containerRef]);
    return (
        <Wrapper
            containerOverflow={containerOverflow}
            ref={containerRef}
            onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => onScrollCallback(e)}
            mobileBreakUnder={mobileBreakUnder}
            marginBottom={marginBottom}
        >
            {children}
        </Wrapper>
    );
};
