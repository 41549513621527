import * as React from 'react';

import { Wrapper } from './table-cell-th.styled';

export interface TableCellThPropsInterface {
    children?: React.ReactNode;
    align?: 'left' | 'center' | 'right';
    width?: number | string;
    sticky?: 'left' | 'right';
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
    locked?: boolean;
    leftGap?: boolean;
    colSpan?: number;
}

export const TableCellTh: React.FC<TableCellThPropsInterface> = ({
    children,
    align = 'left',
    sticky,
    width,
    isScrolled,
    isScrollable,
    isScrollOnEnd,
    locked,
    leftGap = true,
    colSpan,
}: TableCellThPropsInterface) => {
    return (
        <Wrapper
            align={align}
            cellWidth={width}
            sticky={sticky}
            isScrolled={isScrolled}
            isScrollable={isScrollable}
            isScrollOnEnd={isScrollOnEnd}
            locked={locked}
            leftGap={leftGap}
            colSpan={colSpan}
            as="th"
        >
            {children}
        </Wrapper>
    );
};
