import styled, { css } from 'styled-components';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import { GapSizeType } from '../../../../theme/type/gap-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['mobileBreakUnder', 'containerOverflow', 'marginBottom'].includes(prop),
})<{
    mobileBreakUnder?: BreakpointSizeType;
    containerOverflow: boolean;
    marginBottom: GapSizeType;
}>(
    ({ theme, mobileBreakUnder, containerOverflow, marginBottom }) => css`
        border: 0;
        max-width: 100%;
        margin-bottom: ${theme.gap[marginBottom]};

        ${containerOverflow &&
        css`
            overflow-x: auto;
        `}

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].min} {
                margin-left: -${theme.gap.vsmall};
                margin-right: -${theme.gap.vsmall};
                min-width: calc(100% + (2 * ${theme.gap.vsmall}));
            }

            @media ${theme.breakpoints[mobileBreakUnder].max} {
                overflow-x: unset !important;

                th,
                td {
                    width: 100%;
                    min-width: 100%;
                }
            }
        `}
    `,
);
