import * as React from 'react';
import { Label, Value } from '../table-cell.styled';

import { Wrapper } from './table-cell-td.styled';
import { BreakpointSizeType } from '../../../../../theme/type/breakpoints-type';

export interface TableCellTdPropsInterface {
    children?: React.ReactNode;
    align?: 'left' | 'center' | 'right';
    width?: number | string;
    label?: React.ReactNode;
    sticky?: 'left' | 'right';
    mobileBreakUnder?: BreakpointSizeType;
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
    locked?: boolean;
    leftGap?: boolean;
    colSpan?: number;
}

export const TableCellTd: React.FC<TableCellTdPropsInterface> = ({
    children,
    align = 'left',
    sticky,
    width,
    label,
    mobileBreakUnder,
    isScrolled,
    isScrollable,
    isScrollOnEnd,
    locked,
    leftGap = true,
    colSpan,
}: TableCellTdPropsInterface) => {
    return (
        <Wrapper
            align={align}
            cellWidth={width}
            mobileBreakUnder={mobileBreakUnder}
            sticky={sticky}
            isScrolled={isScrolled}
            isScrollable={isScrollable}
            isScrollOnEnd={isScrollOnEnd}
            locked={locked}
            leftGap={leftGap}
            colSpan={colSpan}
        >
            {label && <Label mobileBreakUnder={mobileBreakUnder}>{label}:</Label>}
            {children && <Value mobileBreakUnder={mobileBreakUnder}>{children}</Value>}
        </Wrapper>
    );
};
