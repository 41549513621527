import styled, { css } from 'styled-components';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';

export const Wrapper = styled.thead.withConfig({
    shouldForwardProp: (prop) => !['mobileBreakUnder'].includes(prop),
})<{
    mobileBreakUnder?: BreakpointSizeType;
}>(
    ({ theme, mobileBreakUnder }) => css`
        border: 0;

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].max} {
                display: none;
            }
        `}
    `,
);
