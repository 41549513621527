import * as React from 'react';

import { TableCellTd } from './td/table-cell-td';
import { TableCellTh } from './th/table-cell-th';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';

export interface TableCellPropsInterface {
    children?: React.ReactNode;
    variant?: 'tbody' | 'thead' | 'tfoot';
    align?: 'left' | 'center' | 'right';
    width?: number | string;
    label?: React.ReactNode;
    sticky?: 'left' | 'right';
    mobileBreakUnder?: BreakpointSizeType;
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
    locked?: boolean;
    leftGap?: boolean;
    colSpan?: number;
}

export const TableCell: React.FC<TableCellPropsInterface> = ({
    children,
    variant = 'tbody',
    align = 'left',
    sticky,
    width,
    label,
    mobileBreakUnder,
    isScrolled,
    isScrollable,
    isScrollOnEnd,
    locked,
    leftGap,
    colSpan,
}: TableCellPropsInterface) => {
    if (variant === 'thead') {
        return (
            <TableCellTh
                align={align}
                width={width}
                sticky={sticky}
                isScrolled={isScrolled}
                isScrollable={isScrollable}
                isScrollOnEnd={isScrollOnEnd}
                locked={locked}
                leftGap={leftGap}
                colSpan={colSpan}
            >
                {children}
            </TableCellTh>
        );
    }

    return (
        <TableCellTd
            align={align}
            label={label}
            width={width}
            mobileBreakUnder={mobileBreakUnder}
            sticky={sticky}
            isScrolled={isScrolled}
            isScrollable={isScrollable}
            isScrollOnEnd={isScrollOnEnd}
            locked={locked}
            leftGap={leftGap}
            colSpan={colSpan}
        >
            {children}
        </TableCellTd>
    );
};
