import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../theme/type/gap-type';

export const Wrapper = styled.table.withConfig({
    shouldForwardProp: (prop) => !['marginTop', 'locked'].includes(prop),
})<{
    marginTop?: GapSizeType;
    locked?: boolean;
}>(
    ({ theme, marginTop, locked }) => css`
        border: 0;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        margin-bottom: 1rem;

        ${marginTop &&
        css`
            margin-top: ${theme.gap[marginTop]};
        `}

        ${locked &&
        css`
            filter: blur(0.3rem);
        `}
    `,
);
