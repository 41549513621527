import styled, { css } from 'styled-components';

import { Wrapper as TableCell } from '../table-cell.styled';

export const Wrapper = styled(TableCell).withConfig({
    shouldForwardProp: (prop) =>
        !['align', 'cellWidth', 'sticky', 'isScrolled', 'isScrollable', 'isScrollOnEnd', 'locked', 'leftGap'].includes(
            prop,
        ),
})<{
    align?: 'left' | 'center' | 'right';
    cellWidth?: number | string;
    sticky?: 'left' | 'right';
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
    locked?: boolean;
    leftGap?: boolean;
}>`
    ${({ theme, locked, leftGap, cellWidth }) => css`
        color: ${theme.palette.common.textMuted};
        text-transform: uppercase;
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.lineHeight};

        ${!leftGap &&
        css`
            padding-left: 0;
        `}

        ${locked &&
        css`
            filter: blur(0.3rem);
        `}

        ${typeof cellWidth === 'string' &&
        css`
            width: ${cellWidth};
            min-width: ${cellWidth};
        `}

        ${typeof cellWidth === 'number' &&
        css`
            width: ${`${cellWidth}rem`};
            min-width: ${`${cellWidth}rem`};
        `}
    `}
`;
