import styled, { css } from 'styled-components';
import { Wrapper as TableCell } from '../table-cell.styled';
import { BreakpointSizeType } from '../../../../../theme/type/breakpoints-type';

export const Wrapper = styled(TableCell).withConfig({
    shouldForwardProp: (prop) =>
        ![
            'align',
            'cellWidth',
            'sticky',
            'mobileBreakUnder',
            'isScrolled',
            'isScrollable',
            'isScrollOnEnd',
            'locked',
            'leftGap',
        ].includes(prop),
})<{
    align?: 'left' | 'center' | 'right';
    cellWidth?: number | string;
    sticky?: 'left' | 'right';
    mobileBreakUnder?: BreakpointSizeType;
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
    locked?: boolean;
    leftGap?: boolean;
}>`
    ${({ theme, mobileBreakUnder, locked, leftGap, cellWidth }) => css`
        color: ${theme.palette.color.black.main};
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.medium.lineHeight};

        ${!leftGap &&
        css`
            padding-left: 0;
        `}

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].max} {
                border-top: 0.1rem solid ${theme.palette.common.divider};
                padding: ${theme.gap.vsmall} 0;
                display: flex;
                justify-content: space-between;

                &:first-child {
                    border-top: unset;
                }

                &:empty {
                    display: none;
                }
            }
        `}

        ${locked &&
        css`
            filter: blur(0.3rem);
        `}

        ${typeof cellWidth === 'string' &&
        css`
            width: ${cellWidth};
            min-width: ${cellWidth};
        `}

        ${typeof cellWidth === 'number' &&
        css`
            width: ${`${cellWidth}rem`};
            min-width: ${`${cellWidth}rem`};
        `}
    `}
`;
