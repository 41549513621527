import * as React from 'react';
import { Wrapper } from './table-row.styled';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';

export interface TableRowPropsInterface {
    children: React.ReactElement | React.ReactElement[];
    variant?: 'tbody' | 'thead' | 'tfoot';
    mobileBreakUnder?: BreakpointSizeType;
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
    locked?: boolean;
    isActive?: boolean;
    borderBottom?: boolean;
    borderTop?: boolean;
    onClick?: () => void;
    className?: string;
    isSummary?: boolean;
}

export const TableRow: React.FC<TableRowPropsInterface> = ({
    children,
    variant,
    mobileBreakUnder,
    isScrolled,
    isScrollable,
    isScrollOnEnd,
    locked,
    borderBottom = true,
    borderTop,
    isActive = false,
    onClick,
    className,
    isSummary = false,
}) => {
    if (Array.isArray(children)) {
        return (
            <Wrapper
                mobileBreakUnder={mobileBreakUnder}
                isActive={isActive}
                borderBottom={borderBottom}
                borderTop={borderTop}
                onClick={onClick}
                className={className}
                isSummary={isSummary}
            >
                {children.map((child, index: number) => {
                    return React.cloneElement(child, {
                        variant,
                        mobileBreakUnder,
                        isScrolled,
                        isScrollable,
                        isScrollOnEnd,
                        locked,
                        ...child.props,
                        key: index,
                    });
                })}
            </Wrapper>
        );
    }

    return (
        <Wrapper
            mobileBreakUnder={mobileBreakUnder}
            isActive={isActive}
            borderBottom={borderBottom}
            borderTop={borderTop}
            onClick={onClick}
            className={className}
            isSummary={isSummary}
        >
            {React.cloneElement(children, {
                variant,
                isScrolled,
                isScrollable,
                isScrollOnEnd,
                locked,
                mobileBreakUnder,
                ...children.props,
            })}
        </Wrapper>
    );
};
