import React, { useState, useRef } from 'react';
import { Loading } from '../loading/loading';
import { TableContainer } from './container/table-container';

import { Wrapper } from './table.styled';
import { GapSizeType } from '../../../theme/type/gap-type';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';

interface TablePropsInterface {
    children: React.ReactElement[] | React.ReactElement;
    marginTop?: GapSizeType;
    loading?: boolean;
    mobileBreakUnder?: BreakpointSizeType;
    locked?: boolean;
    border?: boolean;
    fullWidth?: boolean;
    containerOverflow?: boolean;
    containerMarginBottom?: GapSizeType;
}

export const Table: React.FC<TablePropsInterface> = ({
    children,
    marginTop = 'none',
    loading = false,
    mobileBreakUnder,
    border = true,
    locked,
    containerOverflow = true,
    fullWidth,
    containerMarginBottom = 'large',
}) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isScrollOnEnd, setIsScrollOnEnd] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    const tableRef = useRef<HTMLTableElement | null>(null);

    const onScrollCallback = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const target = e.target as HTMLElement;
        if (target) {
            setIsScrolled(target.scrollLeft > 1);

            if (tableRef.current) {
                setIsScrollOnEnd(tableRef.current.scrollWidth - target.offsetWidth === target.scrollLeft);
            }
        }
    };

    const onLoadCallback = (e: HTMLDivElement | null) => {
        setIsScrollable(e?.offsetWidth !== tableRef.current?.offsetWidth);
    };

    if (Array.isArray(children)) {
        return (
            <Loading fullWidth={fullWidth} active={loading}>
                <TableContainer
                    mobileBreakUnder={mobileBreakUnder}
                    onScrollCallback={onScrollCallback}
                    onLoadCallback={onLoadCallback}
                    containerOverflow={containerOverflow}
                    marginBottom={containerMarginBottom}
                >
                    <Wrapper marginTop={marginTop} ref={tableRef} locked={locked}>
                        {children.map((child, index: number) => {
                            return React.cloneElement(child, {
                                mobileBreakUnder,
                                isScrolled,
                                isScrollable,
                                isScrollOnEnd,
                                locked,
                                border,
                                key: index,
                            });
                        })}
                    </Wrapper>
                </TableContainer>
            </Loading>
        );
    }

    return (
        <Loading fullWidth={fullWidth} active={loading}>
            <TableContainer
                mobileBreakUnder={mobileBreakUnder}
                onScrollCallback={onScrollCallback}
                onLoadCallback={onLoadCallback}
                containerOverflow={containerOverflow}
                marginBottom={containerMarginBottom}
            >
                <Wrapper ref={tableRef} locked={locked}>
                    {React.cloneElement(children, {
                        isScrolled,
                        isScrollable,
                        isScrollOnEnd,
                        locked,
                        border,
                        mobileBreakUnder,
                    })}
                </Wrapper>
            </TableContainer>
        </Loading>
    );
};
