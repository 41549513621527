import styled, { css, DefaultTheme } from 'styled-components';
import { getOpacity } from '../../../../theme/utils/get-rgb';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';

const stickyStyles = (
    theme: DefaultTheme,
    sticky: 'left' | 'right',
    isScrolled: boolean | undefined,
    isScrollable: boolean | undefined,
    isScrollOnEnd: boolean | undefined,
) => {
    return css`
        position: sticky;
        background-color: ${theme.palette.background.body};
        z-index: 100;

        ${sticky === 'left' &&
        css`
            left: 0;

            ${isScrolled &&
            css`
                &:after {
                    box-shadow: inset 10px 0 8px -8px ${getOpacity(theme.palette.color.black.main, 0.3)};
                    right: 0;
                    position: absolute;
                    top: 0;
                    bottom: -1px;
                    width: 3rem;
                    transform: translate(100%);
                    transition: box-shadow 0.3s;
                    content: '';
                    pointer-events: none;
                }
            `}
        `}

        ${sticky === 'right' &&
        css`
            right: 0;

            ${isScrolled &&
            css`
                &:before {
                    box-shadow: inset -10px 0 8px -8px ${getOpacity(theme.palette.color.black.main, 0.3)};
                    left: -6rem;
                    position: absolute;
                    top: 0;
                    bottom: -1px;
                    width: 3rem;
                    transform: translate(100%);
                    transition: box-shadow 0.3s;
                    content: '';
                    pointer-events: none;
                }
            `}

            ${isScrollable &&
            css`
                &:before {
                    box-shadow: inset -10px 0 8px -8px ${getOpacity(theme.palette.color.black.main, 0.3)};
                    left: -6rem;
                    position: absolute;
                    top: 0;
                    bottom: -1px;
                    width: 3rem;
                    transform: translate(100%);
                    transition: box-shadow 0.3s;
                    content: '';
                    pointer-events: none;
                }
            `}
        `}

        ${isScrollOnEnd &&
        css`
            &:before {
                display: none !important;
            }
        `}
    `;
};

export const Value = styled.div.withConfig({
    shouldForwardProp: (prop) => !['mobileBreakUnder'].includes(prop),
})<{
    mobileBreakUnder?: BreakpointSizeType;
}>(
    ({ theme, mobileBreakUnder }) => css`
        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].max} {
                text-align: right;
                flex: 1;
                /* justify-content: flex-end !important;
                display: inline-flex !important; */
            }
        `}
    `,
);

export const Wrapper = styled.td.withConfig({
    shouldForwardProp: (prop) =>
        !['align', 'cellWidth', 'mobileBreakUnder', 'sticky', 'isScrolled', 'isScrollable', 'isScrollOnEnd'].includes(
            prop,
        ),
})<{
    align?: 'left' | 'center' | 'right';
    cellWidth?: number | string;
    mobileBreakUnder?: BreakpointSizeType;
    sticky?: 'left' | 'right';
    isScrolled?: boolean;
    isScrollable?: boolean;
    isScrollOnEnd?: boolean;
}>`
    ${({ theme, align, cellWidth, sticky, isScrolled, isScrollable, isScrollOnEnd, mobileBreakUnder }) => css`
        border: 0;
        padding: ${theme.gap.small} ${theme.gap.small};

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].min} {
                ${sticky &&
                css`
                    ${stickyStyles(theme, sticky, isScrolled, isScrollable, isScrollOnEnd)};
                `}
            }

            @media ${theme.breakpoints[mobileBreakUnder].max} {
                &:first-child {
                    padding-left: unset;
                }

                &:last-child {
                    padding-right: unset;
                }
            }
        `}

        ${!mobileBreakUnder &&
        css`
            ${sticky &&
            css`
                ${stickyStyles(theme, sticky, isScrolled, isScrollable, isScrollOnEnd)};
            `}
        `}

        ${align &&
        css`
            text-align: ${align};
        `}

        ${cellWidth &&
        css`
            min-width: ${typeof cellWidth === 'number' ? `${cellWidth}px` : cellWidth};
        `}
    `}
`;

export const Label = styled.div.withConfig({
    shouldForwardProp: (prop) => !['mobileBreakUnder'].includes(prop),
})<{
    mobileBreakUnder?: BreakpointSizeType;
}>(
    ({ theme, mobileBreakUnder }) => css`
        display: none;

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].max} {
                font-weight: 400;
                display: flex;
                color: ${theme.palette.color.gray.main};
                margin-right: ${theme.gap.small};
            }
        `}
    `,
);
