import styled, { css } from 'styled-components';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import { getOpacity } from '../../../../theme/utils/get-rgb';

export const Wrapper = styled.tr.withConfig({
    shouldForwardProp: (prop) =>
        !['mobileBreakUnder', 'isActive', 'borderTop', 'borderBottom', 'isSummary'].includes(prop),
})<{
    mobileBreakUnder?: BreakpointSizeType;
    isActive: boolean;
    borderBottom?: boolean;
    borderTop?: boolean;
    isSummary: boolean;
}>(
    ({ theme, mobileBreakUnder, isActive, borderBottom, borderTop, isSummary }) => css`
        border-bottom: ${borderBottom ? `0.1rem solid ${theme.palette.common.border}` : 'unset'};
        border-top: ${borderTop ? `0.1rem solid #B3B5CD` : 'unset'};

        ${isSummary &&
        css`
            td {
                border-top: 0.4rem solid #b3b5cd;

                ${isActive &&
                css`
                    border-top: 0;
                `}
            }
        `}

        ${isActive &&
        css`
            td {
                background: ${getOpacity(theme.palette.color.secondary.main, 0.04)};
                box-shadow: inset 0 0.1rem 0.1rem ${getOpacity(theme.palette.color.secondary.main, 0.07)};

                &:first-child {
                    border-radius: 99rem 0 0 99rem;
                }
                &:last-child {
                    border-radius: 0 99rem 99rem 0;
                }
            }
        `}

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].min} {
                border-bottom: ${borderBottom ? `0.1rem solid ${theme.palette.common.border}` : 'unset'};
            }

            @media ${theme.breakpoints[mobileBreakUnder].max} {
                display: flex;
                flex-direction: column;
                border-bottom: 0.1rem dashed ${theme.palette.common.divider};
                padding: ${theme.gap.none};
                position: relative;

                & + & {
                    margin-top: ${theme.gap.medium};
                }
            }
        `}
    `,
);
